<template>
  <div class="scroll">
    <div class="scroll-title">Partners</div>

    <div class="scroll-item">
      <vue3-seamless-scroll :list="rightList" class="seamless-scroll" direction="right" :singleLine="true">
        <div class="item-wrap item-right" v-for="(item, index) in rightList" :key="index">
          <img :src="item" />
        </div>
      </vue3-seamless-scroll>
    </div>
    <div class="scroll-item">
      <vue3-seamless-scroll :list="leftList" class="seamless-scroll" direction="left" :singleLine="true">
        <div class="item-wrap item-left" v-for="(item, index) in leftList" :key="index">
          <img :src="item" />
        </div>
      </vue3-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";

export default {
  name: '',
  components: {
    Vue3SeamlessScroll
  },
  setup() {
    return {
      rightList: [
        require("@/assets/img/official/scroll-r1.svg"),
        require("@/assets/img/official/scroll-r2.png"),
        require("@/assets/img/official/scroll-r3.svg"),
        require("@/assets/img/official/scroll-r4.svg"),
        require("@/assets/img/official/scroll-r5.svg"),
        require("@/assets/img/official/scroll-r6.svg"),
        require("@/assets/img/official/scroll-r7.svg"),
        require("@/assets/img/official/scroll-r8.svg"),
      ],
      leftList: [
        require("@/assets/img/official/scroll-l1.png"),
        require("@/assets/img/official/scroll-l2.svg"),
        require("@/assets/img/official/scroll-l3.svg"),
        require("@/assets/img/official/scroll-l4.svg"),
        require("@/assets/img/official/scroll-l5.svg"),
        require("@/assets/img/official/scroll-l6.svg"),
        require("@/assets/img/official/scroll-l7.svg"),
        require("@/assets/img/official/scroll-l8.svg"),
      ]
    }
  }
}
</script>

<style scoped>
.scroll-title {
  margin-bottom: 50px;
  padding: 0 110px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.scroll-item {
  margin-bottom: 20px;
}

.scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.item-wrap {
  margin: 0 20px;
  width: 212px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #55A856;
}

.item-wrap img {
  width: 150px;
}

.item-right:nth-child(6) img {
  width: 90px;
}

.item-right:nth-child(7) img {
  width: 90px;
}

.item-left:nth-child(1) img {
  width: 90px;
}

@media screen and (max-width: 768px) {
  .scroll-title {
    margin:20px 0 30px;
    padding: 0 20px;
    font-size: 32px;
    font-weight: bold;
  }

  .item-wrap {
    height: 105px;
  }
}
</style>