<template>
  <div class="stepper" :class="{'stepper2': theme == 'black'}">
    <div class="stepper-title">
      <div>LBD发展规划</div>
    </div>
    <q-stepper
      v-model="step"
      ref="stepper"
      animated
      flat
      header-nav
      active-icon="language"
      :vertical="isVertical()"
    >
      <q-step
        :name="1"
        title="2023"
        icon="language"
        :done="step > 1"
      >
        <div class="step-label">Q4</div>
        <div>链上哈希娱乐</div>
        <div>链上娱乐城</div>
      </q-step>

      <q-step
        :name="2"
        title="2024"
        icon="language"
        :done="step > 2"
      >
        <div class="step-label">Q1</div>
        <div>链上商城</div>
        <div>LBD Blockchain mall</div>
        <div class="step-label">Q2</div>
        <div>链游</div>
        <div>LBD DEFL 借贷</div>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'OfficialStepper',
  components: {},
  props: {
    isXs: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: "white"
    }
  },
  setup() {
    return {
      step: ref(1)
    }
  },
  methods: {
    isVertical() {
      if(this.isXs) {
        return true
      }
      if(this.$store.state.mode == 'md') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.stepper {
  margin-top: 200px;
  padding: 0 110px;
}

.stepper-title {
  margin-bottom: 50px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.stepper :deep(.q-stepper) {
  background-color: transparent;
}

.stepper :deep(.q-stepper__title) {
  color: #fff;
  font-size: 30px;
}

.stepper :deep(.q-stepper__step-content) {
  color: #fff;
  font-size: 15px;
}

.stepper :deep(.q-stepper__tab--active) {
  color: #55A856;
}

.stepper :deep(.q-stepper__tab--done) {
  color: #55A856;
}

.step-label {
  margin-top: 15px;
  font-weight: bold;
  color: #ffde89;
}

.stepper2 :deep(.q-stepper__title) {
  color: #000;
}

.stepper2 :deep(.q-stepper__step-content) {
  color: #000 !important;
}

.stepper2 .step-label {
  margin-top: 15px;
  font-weight: bold;
  color: #6EADFD;
}


@media screen and (max-width: 1000px) {
  .stepper {
    margin-top: 30px;
    padding: 0 20px;
  }

  .stepper-title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .stepper :deep(.q-stepper__title) {
    color: #fff;
    font-size: 18px;
  }

  .stepper2 :deep(.q-stepper__title) {
    color: #000;
  }

  .stepper2 :deep(.q-stepper__step-content) {
    color: #000 !important;
  }

}
</style>